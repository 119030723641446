<template>
  <div>
    <a-breadcrumb style="padding-top: 15px">
      <a-breadcrumb-item><a href="/">首页</a></a-breadcrumb-item>
      <a-breadcrumb-item>设备</a-breadcrumb-item>
      <a-breadcrumb-item>设备列表</a-breadcrumb-item>
    </a-breadcrumb>
    <a-page-header style="border: 1px solid rgb(235, 237, 240)" title="设备列表"/>
    <br>
    <a-row>
      <a-col :xs="22" :xl="22" :xxl="23">
      </a-col>
      <a-col :span="1">
        <a-button type="primary" v-on:click="add">
          添加
        </a-button>
      </a-col>
    </a-row>
    <br>
    <a-row>
      <a-col>
        <span style="line-height: 32px">小区名称：</span>
        <a-select style="width: 120px" v-model="areaCode" @change="handleAreaChange">
          <a-select-option v-for="area in areaList" :key="area.code">
            {{ area.name }}
          </a-select-option>
        </a-select>
      </a-col>
    </a-row>
    <br>
    <a-row>
      <a-col>
        <a-input placeholder="设备编号/名称" v-model="name" style="width:120px"></a-input>
        <a-button v-on:click="search" type="primary">搜索</a-button>
      </a-col>
    </a-row>
    <br>
    <a-table :columns="columns" :data-source="list" :pagination="pagination" @change="handleTableChange"
             :rowKey="(record,index)=>{return index}">
			<span slot="operation" slot-scope="text,record">
				<a-button v-on:click="check(record.id)">查看详情</a-button>
				<a-button v-on:click="change(record.id)" type="primary">修改状态</a-button>
			</span>
      <span slot="projectName">
				<span>盐都区环保屋项目</span>
			</span>
      <span slot="equipmentStatus" slot-scope="equipmentStatus">
				<a-tag :color="equipmentStatus === '1' ? 'green' :  'red'">
					{{ equipmentStatus === '1' ? '已启用' : '未启用' }}
				</a-tag>
			</span>
    </a-table>
    <a-modal v-model="visible" @ok="handleOk" okText="确定" cancelText="取消" @cancel="handleCancel">
      是否修改此设备的通讯状态？
    </a-modal>
    <!--设备详情弹窗-->
    <a-modal v-model="checkVisible" @ok="checkHandleOk" okText="确定" cancelText="取消" @cancel="checkHandleCancel">
      <p>设备号：
        <a-input v-model="deviceNumber" disabled></a-input>
      </p>
      <p>设备名称：
        <a-input v-model="deviceName" disabled></a-input>
      </p>
      <p>小区名称：
        <a-input v-model="areaName" disabled></a-input>
      </p>
      <!-- <p>设备状态：<a-input v-model="deviceStatus" disabled></a-input></p> -->
      <p>最后通讯时间：
        <a-input v-model="lastTime" disabled></a-input>
      </p>
    </a-modal>
    <!--添加设备弹窗-->
    <a-modal v-model="addVisible" @ok="addHandleOk" okText="确定" cancelText="取消" @cancel="addHandleCancel">
      <p>设备号：
        <a-input v-model="addNumber"></a-input>
      </p>
      <p>设备名称：
        <a-input v-model="addName"></a-input>
      </p>
      <p>选择小区：
        <!--        <a-select style="width:200px" @change="handleChange" placeholder="选择小区">-->
        <!--          <a-select-option :key="item.areaCode" v-for="item in villages" :value="item.areaCode">-->
        <!--            {{ item.areaName }}-->
        <!--          </a-select-option>-->
        <!--        </a-select>-->
        <a-select style="width: 120px" v-model="addCode" @change="addHandleAreaChange">
          <a-select-option v-for="k in addAreaList" :key="k.code">
            {{ k.name }}
          </a-select-option>
        </a-select>
      </p>
      <p>详细地址：
        <a-input v-model="addAddress"></a-input>
      </p>
    </a-modal>
  </div>
</template>
<script>
import axios from "axios";

const columns = [{
  title: '设备号',
  dataIndex: 'terminalNo',
  key: 'terminalNo',

},
  {
    title: '设备名称',
    dataIndex: 'terminalName',
    key: 'terminalName',

  },
  {
    title: '项目名称',
    dataIndex: 'projectName',
    key: 'projectName',
    scopedSlots: {
      customRender: 'projectName'
    }
  },
  {
    title: '小区名称',
    dataIndex: 'areaName',
    key: 'areaName',

  },
  {
    title: '小区编码',
    dataIndex: 'areaCode',
    key: 'areaCode',

  },
  // {
  // 	title: '最后通讯时间',
  // 	dataIndex: 'lastTime',
  // 	key: 'lastTime',

  // },
  {
    title: '状态',
    dataIndex: 'equipmentStatus',
    key: 'equipmentStatus',
    scopedSlots: {
      customRender: 'equipmentStatus'
    }

  },

  {
    title: '操作',
    dataIndex: 'operation',
    scopedSlots: {
      customRender: 'operation'
    }

  }
];
export default {
  components: {},
  data() {
    return {
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
        showTotal: total => `共 ${total} 条`, // 显示总数
      },
      columns,
      //删除确认框
      visible: false,
      list: [],
      areaCode: "", //当前选中的地区值
      changeId: "", //准备删除的设备id
      checkId: "", //查看详情的设备id
      name: "",//搜索框
      checkVisible: false,//查看详情弹窗
      lastTime: "", //最后通讯时间
      deviceNumber: "", //设备号
      deviceName: "", //设备名称
      areaName: "",//小区名称
      deviceStatus: "",//设备状态
      addVisible: false, //添加设备弹窗
      addNumber: "", //添加设备设备号
      addName: "", //添加设备名称
      areaList: [],
      addCode: "",//添加设备小区code
      addAreaList: [],//添加设备小区
      villages: [], //添加下拉框所有的值
      addAddress: ''


    }
  },
  created() {
    axios.post("/area/areaSelect", {}).then((res) => {
      if (res.data.code === '200') {
        //请求成功
        this.areaList = res.data.data;
        this.areaCode = this.areaList[0].code;
      }
    });

    axios.post("/area/areaSelectWithoutAll", {}).then((res) => {
      if (res.data.code === '200') {
        //请求成功
        this.addAreaList = res.data.data;
        this.addCode = this.addAreaList[0].code;
      }
    })


  },
  methods: {
    reset: function () {
      this.pagination.current = 1;
      this.areaCode = "";
      this.getData()
    },
    //修改状态
    change: function (record) {
      //获取这一行数据的ID，放入内存中
      this.changeId = record;
      //弹出编辑框
      this.visible = true;
    },
    //查看详情
    check: function (record) {
      this.checkId = record;
      this.checkVisible = true;
      axios.post("/terminal/terminalLook", {
        "id": this.checkId
      }).then((res) => {
        if (res.data.code === '200') {
          const resData = res.data.data;
          this.deviceNumber = resData.terminalNo;
          this.deviceName = resData.terminalName;
          this.areaName = resData.areaName;
          this.lastTime = resData.lastTime;
          this.deviceStatus = resData.status;
        }
      })
    },
    //添加设备
    add: function () {
      this.addVisible = true;
      axios.post("/equipment/checkArea", {}).then((res) => {
        if (res.data.code === '200') {
          this.villages = res.data.data;
        }
      })
    },
    //添加设备确认按钮
    addHandleOk: function () {
      axios.post("/terminal/terminalInsert", {
        "terminalNo": this.addNumber,
        "terminalName": this.addName,
        "address": this.addAddress,
        "areaCode": this.addCode
      }).then((res) => {
        if (res.data.code === '200') {
          this.addVisible = false;
          this.addNumber = "";
          this.addName = "";
          this.addAddress = "";
          this.addCode = this.addAreaList[0].code;
          this.$message.success(res.data.msg);
          this.getData();
        } else {
          this.$message.error(res.data.msg);
        }
      })
    },
    //添加设备取消按钮
    addHandleCancel: function () {
      this.addVisible = false;
      this.addNumber = "";
      this.addName = "";
      this.addAddress = "";
    },
    //添加弹窗下拉框监听
    handleChange: function (val) {
      this.addCode = val;
    },
    //分页监听方法
    handleTableChange: function (pagination) {
      this.pagination = pagination;
    },
    //修改状态确认
    handleOk: function () {
      this.visible = false;
      axios.post("/terminal/statusUpdate", {
        "id": this.changeId
      }).then((res) => {
        if (res.data.code === '200') {
          this.$message.success(res.data.msg);
          this.getData();
        }
      })
    },
    //删除框取消
    handleCancel: function () {
      this.changeId = "";
      this.visible = false;
    },
    //详情弹窗取消按钮
    checkHandleCancel: function () {
      this.checkVisible = false;
    },
    //详情弹窗确定按钮
    checkHandleOk: function () {
      this.checkVisible = false;
    },
    search: function () {
      this.pagination.current = 1;
      this.getData();
    },
    getData: function () {
      //从服务器获取数据
      axios.post("/terminal/terminalList", {
        "pageNumber": this.pagination.current,
        "areaCode": this.areaCode,
        "name": this.name
      }).then((res) => {
        if (res.data.code === '200') {
          //执行成功
          this.list = res.data.data.list;
          this.pagination.total = parseInt(res.data.data.count);
        }
      })
    },
    //获取子组件传值
    handleAreaChange: function (areaCode) {
      this.areaCode = areaCode;
    },
    //添加设备获取子组件传值
    addHandleAreaChange: function (code) {
      this.addCode = code;
    },
  },
  watch: {
    pagination() {
      this.getData();
    },
    areaCode() {
      this.pagination.current = 1;
      this.getData();
    },
  }
}
</script>
