<template>
  <div class="video_con" style="height: 100%;width: 100%">
    <video controls class="video" ref="video" style="width: 100%;height:100%"></video>
  </div>
</template>

<script>
// npm install hls.js -S
let Hls = require('hls.js');
export default {
  data() {
    return {
      hls: null,
      url: ""
    }
  },
  created() {
    this.url = this.$route.query.url
    console.log(this.url)
  },
  mounted() {
    this.getStream(this.url)
  },
  methods: {
    getStream(source) {
      if (Hls.isSupported()) {
        this.hls = new Hls();
        this.hls.loadSource(source);
        this.hls.attachMedia(this.$refs.video);

        this.hls.on(Hls.Events.MANIFEST_PARSED, () => {
          console.log("加载成功");
          this.$refs.video.play();
        });
      } else if (this.$refs.video.canPlayType('application/vnd.apple.mpegurl')) {
        this.$refs.video.src = 'https://video-dev.github.io/streams/x36xhzz/x36xhzz.m3u8';
        this.$refs.video.addEventListener('loadedmetadata', function () {
          this.$refs.video.play();
        });
      }
    },
    // 停止流
    videoPause() {
      if (this.hls) {
        this.$refs.video.pause();
        this.hls.destroy();
        this.hls = null;
      }
    }
  }
}
</script>