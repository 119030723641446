<template>
  <div>
    <a-breadcrumb style="padding-top: 15px">
      <a-breadcrumb-item><a href="/">首页</a></a-breadcrumb-item>
      <a-breadcrumb-item>数据中心</a-breadcrumb-item>
      <a-breadcrumb-item>数据图</a-breadcrumb-item>
    </a-breadcrumb>
    <a-page-header style="border: 1px solid rgb(235, 237, 240)" title="数据图"/>
    <br>
    <a-row type="flex">
      <a-col><span style="line-height: 32px"></span>
        <a-select style="width: 120px" v-model="areaCode" @change="handleAreaChange">
          <a-select-option v-for="area in areaList" :key="area.code">
            {{ area.name }}
          </a-select-option>
        </a-select>
      </a-col>
    </a-row><br><br>

    <a-row type="flex">
      <a-col>
        <span style="line-height: 32px">时间：</span>
      </a-col>
      <a-col>
        <a-range-picker @change="timeChange" :locale="locale" :value="createValue"/>
      </a-col>
    </a-row><br>

    <a-col :span="1"></a-col>
    <a-col>
      <a-button type="primary" v-on:click="search">生成图表</a-button>
    </a-col><br><br><br>

    <div v-if="version === '1'">
      <a-row>
        <a-col :span="8">
          <a-col style="font-size: 25px;text-align: center">
            <p>发卡率</p>
          </a-col>
          <ve-pie :data="pieData1" :colors="appColor1"></ve-pie>
        </a-col>

        <a-col :span="8">
          <a-col style="font-size: 25px;text-align: center">
            <p>投放率</p>
          </a-col>
          <ve-pie :data="pieData2" :colors="appColor2"></ve-pie>
        </a-col>

        <a-col :span="8">
          <a-col style="font-size: 25px;text-align: center">
            <p>合格率</p>
          </a-col>
          <ve-pie :data="pieData3" :colors="appColor3"></ve-pie>
        </a-col>
      </a-row>

      <a-row>
        <a-col style="font-size: 25px" span="5">
          <p>设备投放重量</p>
        </a-col>
        <a-col style="font-size: 25px"  offset="8" span="5">
          <p>发袋记录</p>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="11">
          <ve-line :data="terminalRecycle"></ve-line>
        </a-col>
        <a-col offset="2" :span="10">
          <ve-line :data="sendBag"></ve-line>
        </a-col>
      </a-row>
    </div>

    <div v-if="version === '2'">
      <a-row>
        <a-col style="font-size: 25px" :span="5">
          <p>清运</p>
        </a-col>
        <a-col style="font-size: 25px" offset="8" :span="5">
          <p>回收</p>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="11">
          <ve-line :data="garbage"></ve-line>
        </a-col>
        <a-col :span="10" offset="2">
          <ve-line :data="recycle"></ve-line>
        </a-col>
      </a-row>
    </div>
  </div>
</template>
<script>
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';
import axios from 'axios'
import moment from 'moment';

export default {
  created() {
    // if (sessionStorage.getItem("token") == null) {
    // 	//没有token
    // 	this.$router.push("/login")
    // }
    this.version = sessionStorage.getItem("version");

    axios.post("/area/areaSelect", {}).then((res) => {
      if (res.data.code === '200') {
        //请求成功
        this.areaList = res.data.data;
        this.areaCode = this.areaList[0].code;
        this.getData();
      }
    })
    this.getDate();
  },
  components: {},
  data() {
    return {
      //饼图1
      pieData1: {
        columns: ['name', 'value'],
        rows: [
          // {'name': '已发放', 'value': '10'},
          // {'name': '未发放', 'value': '20'},
        ]
      },

      //饼图2
      pieData2: {
        columns: ['name', 'value'],
        rows: [
          // {'name': '已投放', 'value': '50'},
          // {'name': '未发放', 'value': '30'},
        ]
      },

      //饼图3
      pieData3: {
        columns: ['name', 'value'],
        rows: [
          // {'name': '合格', 'value': '100'},
          // {'name': '未合格', 'value': '10'},
        ]
      },
      appColor1: ['#41df5c', '#bccdc5'],
      appColor2: ['#1bd2a6', '#bccdc5'],
      appColor3: ['#1bd2a6', '#f67983'],

      //设备投放重量
      terminalRecycle: {
        // columns: ['date', '厨余', '其他','有害'],
        // rows: [
        //   {'date': '', '厨余': '', '其他':0 ,'有害':''},
        // ]
        columns: ['date', '厨余'],
        rows: [
          {'date': '', '厨余': ''},
        ]
      },

      //发袋记录
      sendBag: {
        columns: ['date', '积分领袋','活动赠袋'],
        rows: [
          { 'date': '','积分领袋': '','活动赠袋': ''},
        ]
      },

      //建湖清运
      garbage: {
        columns: ['date', '厨余','其他','有害'],
        rows: [
          {'date': '2-12', '厨余': '2321','其他': '4548','有害': '4648'},
        ],
      },

      //建湖回收
      recycle: {
        columns: ['date', '纸张','塑料','金属','玻璃','织物'],
        rows: [
          {'date': '2-18', '纸张': '1644','塑料': '2568','金属': '1448','玻璃':'1535'}
        ],
      },
      locale: locale,
      createValue: [],
      areaCode: "", //当前选中的地区值
      areaList: [],
      startTime: "",
      endTime: "",
      version: '',
      moment
    }
  },

  methods: {
    //获取渲染图表的数据
    getData() {
      if(this.version === '1'){
        //饼图
        axios.post("/chart/chartList", {
          "areaCode": this.areaCode,
        }).then((res) => {
          if (res.data.code === '200') {
            this.pieData1.rows = res.data.data.chart1;
            this.pieData2.rows = res.data.data.chart2;
            this.pieData3.rows = res.data.data.chart3;
          }
        })

      //设备投放折线图
        axios.post("/chart/terminal", {
          "startTime": this.startTime,
          "endTime": this.endTime,
          "areaCode":this.areaCode
        }).then((res) => {
          if (res.data.code === '200') {
            this.terminalRecycle.rows = res.data.data;
          } else {
            this.$message.error(res.data.msg);
          }
        })

        //发袋
        axios.post("/chart/bag", {
          "startTime": this.startTime,
          "endTime": this.endTime,
          "areaCode":this.areaCode
        }).then((res) => {
          if (res.data.code === '200') {
            this.sendBag.rows = res.data.data;
          }
        })
      } else {
        //清运
        axios.post("/chart/clean", {
          "startTime": this.startTime,
          "endTime": this.endTime
        }).then((res) => {
          if (res.data.code === '200') {
            this.garbage.rows = res.data.data;
          }
        })

        //回收
        axios.post("/chart/recycle", {
          "startTime": this.startTime,
          "endTime": this.endTime
        }).then((res) => {
          if (res.data.code === '200') {
            this.recycle.rows = res.data.data;
          }
        })
      }
    },

    //获取当前日期
    getDate() {
      let date = new Date();
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      let sevenDay = date.getTime()-7*86400*1000;
      date.setTime(sevenDay);
      this.startTime = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
      this.endTime = year + '-' + month + '-' + day;
      this.createValue = [this.startTime, this.endTime];
    },

    //搜索按钮点击事件
    search: function () {
      this.getData();
    },

    //时间选择框监听
    timeChange(date, dateString) {
      this.createValue = date;
      this.startTime = dateString[0];
      this.endTime = dateString[1];
    },

    //单选框点击事件
    onChange: function (e) {
      this.type = e.target.value;
    },

    //获取子组件传值
    handleAreaChange: function (areaCode) {
      this.areaCode = areaCode;
    }
  }
}
</script>
