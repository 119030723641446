<template>
  <div>
    <a-breadcrumb style="padding-top: 15px">
      <a-breadcrumb-item><a href="/">首页</a></a-breadcrumb-item>
      <a-breadcrumb-item>垃圾袋管理</a-breadcrumb-item>
      <a-breadcrumb-item>垃圾袋发放记录</a-breadcrumb-item>
    </a-breadcrumb>
    <a-page-header style="border: 1px solid rgb(235, 237, 240)" title="垃圾袋发放记录"/>
    <br>

    <br>
    <a-row type="flex">
      <a-col><span style="line-height: 32px">小区名称：</span>
        <a-select style="width: 120px" v-model="areaCode" @change="handleAreaChange">
          <a-select-option v-for="area in areaList" :key="area.code">
            {{ area.name }}
          </a-select-option>
        </a-select>
      </a-col>
    </a-row>
    <br>
    <a-row type="flex">
      <a-col><span style="line-height: 32px">住户地址：</span>
        <a-input placeholder="地址" v-model="address" style="width:120px"></a-input>
      </a-col>
    </a-row>
    <br>
    <a-row type="flex">
      <a-col>
        <span style="line-height: 32px">兑换时间：</span>
      </a-col>
      <a-col>
        <a-range-picker @change="onChange" :locale="locale" :value="createValue"/>
      </a-col>
    </a-row>
    <br>
    <a-row>
      <a-col>
        <a-button type="primary" v-on:click="search">搜索</a-button>
        <a-button v-on:click="reset">重置</a-button>
      </a-col>
    </a-row>
    <br>
    <a-table :columns="columns" :data-source="list" :pagination="pagination" @change="handleTableChange"
             :rowKey="(record,index)=>{return index}">
			<span slot="type" slot-scope="type">
				<!--      <a-tag-->
        <!--          :color="type === '1' ? 'green' :  'red'"-->
        <!--      >-->
        <!--        {{ type === '1' ? '在职' : '离职' }}-->
        <!--      </a-tag>-->
        <a-tag :color="type === '1' ? '#2db7f5' :'#87d068'">
					{{ type === '1' ? '积分领取' : '活动赠送' }}
				</a-tag>
			</span>
    </a-table>
    <br>
  </div>
</template>
<script>
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';
import axios from "axios";
import moment from 'moment';

const columns = [

  {
    title: '发袋小区',
    dataIndex: 'areaName',
    key: 'areaName',

  },
  {
    title: '起始袋子码',
    dataIndex: 'startNo',
    key: 'startNo',

  },
  {
    title: '发放人',
    dataIndex: 'ope',
    key: 'ope',
  },
  {
    title: '住户地址',
    dataIndex: 'residentAddress',
    key: 'residentAddress',

  },
  {
    title: '领用方式',
    dataIndex: 'type',
    key: 'type',
    scopedSlots: {
      customRender: 'type'
    }

  },
  {
    title: '操作时间',
    dataIndex: 'createDate',
    key: 'createDate',

  }
];
export default {
  components: {},
  data() {
    return {
      columns,
      list: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
        showTotal: total => `共 ${total} 条`, // 显示总数
      },
      startTime: "",
      endTime: "",
      areaCode: "", //当前选中的地区值
      areaList: [],
      address: "",
      locale,
      createValue: [],
      moment
    }
  },

  created() {
    axios.post("/area/areaSelect", {}).then((res) => {
      if (res.data.code === '200') {
        //请求成功
        this.areaList = res.data.data;
        this.areaCode = this.areaList[0].code;
      }
    })

    this.getDate();
  },
  methods: {
    //分页监听方法
    handleTableChange: function (pagination) {
      this.pagination = pagination;
    },
    //时间选择框监听
    onChange(date, dateString) {
      this.createValue = date;
      this.startTime = dateString[0]
      this.endTime = dateString[1]
    },
    //搜索按钮点击事件
    search: function () {
      this.getData();
    },
    //重置按钮点击事件
    reset: function () {
      this.startTime = "";
      this.endTime = "";
      this.address = "";
      this.createValue=[];
      this.pagination.current = 1;

      this.getData();
    },
    getData: function () {
      //从服务器获取数据
      axios.post("/bag/sendList", {
        "pageNumber": this.pagination.current,
        "startTime": this.startTime,
        "endTime": this.endTime,
        "address": this.address,
        "areaCode": this.areaCode
      }).then((res) => {
        if (res.data.code === '200') {
          //执行成功
          this.list = res.data.data.list;
          this.pagination.total = parseInt(res.data.data.count);
        }
      })
    },
    //获取当前日期
    getDate(){
      // let date = new Date();
      // let year = date.getFullYear();
      // let month = date.getMonth()+1;
      // let day = date.getDate();
      // month = month >= 10 ? month : '0' + month;
      // day = day >= 10 ? day : '0' + day;
      // this.startTime = year + '-' +month + '-' + day;
      // this.endTime = year + '-' + month + '-' + day;
      // this.createValue = [this.startTime, this.endTime];
      let date = new Date();
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      let sevenDay = date.getTime()-7*86400*1000;
      date.setTime(sevenDay);
      this.startTime = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
      this.endTime = year + '-' + month + '-' + day;
      this.createValue = [this.startTime, this.endTime];
    },
    //获取子组件传值
    handleAreaChange: function (areaCode) {
      this.areaCode = areaCode;
    }

  },
  watch: {
    pagination() {
      this.getData();
    },
    areaCode() {
      this.pagination.current = 1;
      this.getData();
    }
  }
}
</script>
