<template>
  <div>
    <a-breadcrumb style="padding-top: 15px">
      <a-breadcrumb-item><a href="/">首页</a></a-breadcrumb-item>
      <a-breadcrumb-item>员工管理</a-breadcrumb-item>
      <a-breadcrumb-item>员工列表</a-breadcrumb-item>
    </a-breadcrumb><br>
    <a-row>
      <a-row type="flex">
        <a-col><span style="line-height: 32px">员工姓名：</span></a-col>
        <a-col>
          <a-input placeholder="员工姓名：" v-model="name" type="name" style="width: 150px"></a-input>
        </a-col>
        <a-col offset="1"><span style="line-height: 32px">手机号：</span></a-col>
        <a-col>
          <a-input placeholder="手机号：" v-model="mobile" style="width: 150px"></a-input>
        </a-col>
      </a-row>
      <br>
      <a-col>
        <a-button v-on:click="search"  type="primary" >搜索</a-button>
        <a-button v-on:click="reset" >重置</a-button>
        <a-button v-on:click="add"  style="background:#07c160;color: white">添加</a-button>
      </a-col>

    </a-row><br>
    <a-table :columns="columns" :data-source="list" :pagination="pagination" @change="handleTableChange"  :rowKey="(record,index)=>{return index}">
      <span slot="state" slot-scope="state">
				<a-tag :color="state === '1' ? 'green' : 'red'">
					{{ state === '1' ? '在职' : '离职' }}
				</a-tag>
			</span>
      <span slot="operation" slot-scope="text,record">
        <a-button v-on:click="update(record.id)" type="primary">修改</a-button>
				<a-button v-on:click="deleted(record.id)" type="danger">删除</a-button>
			</span>
    </a-table>

    <!--删除弹窗-->
    <a-modal v-model="deleteVisible" @ok="deleteOk" okText="确认" cancelText="取消" @cancel="deleteCancel">
      <p>确定要删除该账号吗？删除之后该账号不可登录!</p>
      <br>
    </a-modal>
    <!--    修改负责小区-->
    <a-modal v-model="updateVisible" @ok="updateOk" okText="确认" cancelText="取消" @cancel="updateCancel">
      <p>员工名称：
        <a-input v-model="employeeName">
          <a-icon slot="prefix" type="user" />
        </a-input>
      </p>
      <p>手机号：
        <a-input v-model="employeeMobile">
          <a-icon slot="prefix" type="phone" />
        </a-input>
      </p>
      <p>状态：
        <a-select v-model="employeeState" style="width: 120px" @change="typeHandleChange">
          <a-select-option :key=1>
            在职
          </a-select-option>
          <a-select-option :key=0>
            离职
          </a-select-option>
        </a-select>
      </p>

      <p>可切换小区：
        <a-select
            mode="multiple"
            v-model="employeeAreas"
            style="width: 100%"
            placeholder="请选择小区"
            @change="handleChange"
            show-search
            :filter-option="filterOption"
        >
          <a-select-option v-for="area in areas" :key="area.code">
            {{ area.name }}
          </a-select-option>
        </a-select>
      </p>

    </a-modal>

    <!--添加人员弹窗-->
    <a-modal v-model="addVisible" @ok="addOk" okText="确认" cancelText="取消" @cancel="addCancel">
      <p>员工名称：
        <a-input v-model="addName">
          <a-icon slot="prefix" type="user" />
        </a-input>
      </p>
      <p>手机号：
        <a-input v-model="addMobile">
          <a-icon slot="prefix" type="phone" />
        </a-input>
      </p>


    </a-modal>

  </div>
</template>
<script>
import axios from "axios";

const columns = [{
  title: '员工名称',
  dataIndex: 'name',
  key: 'name',
},
  {
    title: '手机号',
    dataIndex: 'mobile',
    key: 'mobile',

  },
  {
    title: '状态',
    dataIndex: 'state',
    key: 'state',
    scopedSlots: {
      customRender: 'state'
    }
  },
  {
    title: '操作',
    dataIndex: 'operation',
    scopedSlots: {
      customRender: 'operation'
    }

  }
];


export default {
  data() {
    return {
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
        showTotal: total => `共 ${total} 条`, // 显示总数
      },
      columns,
      name: '', //搜索框姓名
      mobile: '', //搜索框手机号
      list: [], //列表数据
      deleteVisible:false,  //删除确认框
      deleteId:'', //删除的账户id
      addVisible:false,
      type:'',
      addName:'',
      addMobile:'',
      updateVisible:false,
      employeeId:"",
      employeeName:"",
      employeeMobile:"",
      employeeState:"1",
      employeeAreas:[],
      areas:[]  //可选择小区列表
    }
  },
  created() {
    this.getData();
    this.getAreas();

  },
  methods: {
    search: function() {
      this.pagination.current = 1;
      this.getData();
    },
    reset: function() {
      this.name="";
      this.mobile="";
      this.pagination.current = 1;
      this.getData();
    },

    getAreas:function (){
      axios.post("/employee/areaList", {}).then((res) => {
        if (res.data.code ==='200') {
          //请求成功
          this.areas = res.data.data;
        }
      })
    },
    getData: function() {
      axios.post("/employee/list", {
        "pageNumber": this.pagination.current,
        "name": this.name,
        "mobile": this.mobile
      }).then((res) => {
        if (res.data.code === '200') {
          //请求成功
          this.list = res.data.data.list;
          this.pagination.total = parseInt(res.data.data.count);
        }
      })
    },
    handleTableChange: function(pagination) {
      this.pagination = pagination;
    },
    deleted:function (value){
      this.deleteVisible=true
      this.deleteId=value;
    },
    deleteCancel(){
      this.deleteVisible=false;
    },
    deleteOk(){
      //删除
      axios.post("/employee/delete", {
        "id": this.deleteId,
      }).then((res) => {
        if (res.data.code ==='200') {
          //请求成功
          this.$message.success(res.data.msg);
        }else{
          this.$message.error(res.data.msg);
        }
        this.deleteId="";
        this.deleteVisible=false;
        this.getData();
      })

    },
    add: function() {
      this.addVisible = true;
    },
    addCancel: function() {
      this.addName = '';
      this.addMobile = '';
      this.type = '';
      this.addVisible=false;
    },
    addOk: function() {
      if(this.addMobile==='' || this.addName===''){
        this.$message.error('姓名或者手机号不能为空!');
      }else{
        axios.post("/employee/add", {
          "name": this.addName,
          "mobile": this.addMobile,
          "type": this.type,
        }).then((res) => {
          if (res.data.code === '200') {
            this.addVisible = false;
            this.$message.success(res.data.msg);
            this.addName = '';
            this.addMobile = '';
            this.type = '';
            this.getData();
          }else{
            this.$message.error(res.data.msg);
          }
        })
      }


    },
    handleCompanyChange: function (value) {
      this.type = value;
    },
    handleChange(value) {
      this.employeeAreas=value;
    },
    typeHandleChange(value){
      this.employeeState=value
    },
    update:function (record){
      axios.post("/employee/info", {
        "employeeId": record,
      }).then((res) => {
        if (res.data.code === '200') {
          //请求成功
          this.employeeId = res.data.data.id;
          this.employeeName = res.data.data.name;
          this.employeeMobile = res.data.data.mobile;
          this.employeeState = parseInt(res.data.data.state);
          this.employeeAreas = res.data.data.areas;
        }
      })
      this.updateVisible=true;
    },
    updateCancel:function (){
      this.updateVisible=false;
      this.employeeId="";
      this.employeeName="";
      this.employeeMobile="";
      this.employeeState=1;
      this.employeeAreas=[];
    },
    updateOk:function (){
      axios.post("/employee/update", {
        "employeeId": this.employeeId,
        "employeeName": this.employeeName,
        "employeeMobile": this.employeeMobile,
        "employeeState": this.employeeState,
        "employeeAreas": this.employeeAreas
      }).then((res) => {
        if (res.data.code === '200') {
          this.getData();
          this.updateCancel();

        }
      })
    },
    filterOption(input, option) {
      return (
          option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    }
  },

  watch: {
    pagination() {
      this.getData();
    }
  }
}
</script>
