<template>
  <div id="app" style="height: 100%;width: 100%">
    <a-layout id="components-layout-demo-top-side" v-if="$route.meta.keepAlive">
      <a-layout-header style="background: #4b8fe1;padding-left: 20px;margin-bottom: 0">
        <a-row>
          <a-col :span="21">
            <p style="font-size: 16px;color: #ffffff;">杭州申辉平台管理系统</p>
          </a-col>
          <a-col :span="1">
            <a-icon type="user" style="float: right;line-height: 64px;font-size: 20px"/>
          </a-col>
          <a-col :span="2">
            <template>
              <!--              <a-dropdown :trigger="['click']">-->
              <!--                <a class="ant-dropdown-link" @click="e => e.preventDefault()" style="font-size: 15px">-->
              <!--                 {{employeeName}}<a-icon type="down" />-->
              <!--                </a>-->
              <!--                <a-menu slot="overlay" style="margin-top: 15px">-->
              <!--                  <a-menu-item key="0">-->
              <!--                    <a @click="add">修改密码</a>-->
              <!--                  </a-menu-item>-->
              <!--                  <a-menu-item key="1">-->
              <!--                    <a @click="logout">退出登录</a>-->
              <!--                  </a-menu-item>-->
              <!--                  <a-menu-divider />-->
              <!--                </a-menu>-->
              <!--              </a-dropdown>-->
              <a-dropdown>
                <a-menu slot="overlay">
                  <a-menu-item key="1"><a @click="add">修改密码</a></a-menu-item>
                  <a-menu-item key="2"><a @click="logout">退出登录</a></a-menu-item>
                </a-menu>

                <a-button style="margin-left: -6px" type="link" ghost>
                  <!--                  <img src="https://cdn.gxhbpaas.cn/logo.png" alt="" style="display: inline-block;margin-top: -10px;width: 50px;height: 30px;margin-left: 25px">-->
                  <span style="color: #c9def6">{{ employeeName }} </span>
                  <a-icon type="down"/>

                </a-button>
              </a-dropdown>
            </template>
            <!--            <a-button v-on:click="logout" style="background:slategrey;color: brown">退出</a-button>-->

          </a-col>
        </a-row>

      </a-layout-header>
      <a-layout-content style="padding: 0 3px">
        <a-layout style="padding: 0px 0; background: #fff">
          <a-layout-sider width="200" style="background: #fff">
            <a-menu mode="inline" style="height: 100%" @click="change">

              <!--              <a-sub-menu key="home">-->
              <a-sub-menu key="home" v-if="role && role.indexOf('01-')!==-1">
                <span slot="title">
									<a-icon type="home"/>住户管理
								</span>
                <!--                <a-menu-item key="/user/list">-->
                <a-menu-item key="/user/list" v-if="role && role.indexOf('01-1')!==-1">
                  户信息管理
                </a-menu-item>
              </a-sub-menu>

              <a-sub-menu key="date" v-if="role && role.indexOf('02-')!==-1">
								<span slot="title">
									<a-icon type="area-chart"/>数据中心
								</span>
                <a-menu-item key="/" v-if="role && role.indexOf('02-1')!==-1">
                  数据图
                </a-menu-item>
                <a-menu-item key="/throw/data" v-if="role && role.indexOf('02-2')!==-1">
                  间段数据统计
                </a-menu-item>
              </a-sub-menu>

              <a-sub-menu key="throw" v-if="role && role.indexOf('03-')!==-1">
								<span slot="title">
									<a-icon type="rest"/>垃圾投放
								</span>

                <a-menu-item key="/throw/terminalThrow" v-if="role && role.indexOf('03-1')!==-1">
                  设备投放
                </a-menu-item>
                <a-menu-item key="/throw/jh" v-if="role && role.indexOf('03-2')!==-1">
                  建湖数据
                </a-menu-item>
              </a-sub-menu>

              <a-sub-menu key="bag" v-if="role && role.indexOf('04-')!==-1">
                <span slot="title">
									<a-icon type="inbox"/>垃圾袋管理
								</span>
<!--                <a-menu-item key="/bag/bagList" v-if="role && role.indexOf('04-1')!==-1">-->
<!--                  垃圾袋库存-->
<!--                </a-menu-item>-->
                <a-menu-item key="/bag/send" v-if="role && role.indexOf('04-2')!==-1">
                  垃圾袋发放记录
                </a-menu-item>
              </a-sub-menu>

              <a-sub-menu key="terminal" v-if="role && role.indexOf('05-')!==-1">
								<span slot="title">
									<a-icon type="robot"/>设备管理
								</span>
                <a-menu-item key="/equipment/equipment" v-if="role && role.indexOf('05-1')!==-1">
                  设备列表
                </a-menu-item>
                <a-menu-item key="/equipment/terminal" v-if="role && role.indexOf('05-2')!==-1">
                  监控列表
                </a-menu-item>
              </a-sub-menu>
              <a-sub-menu key="container" >
								<span slot="title">
									<a-icon type="container"/>台账管理
								</span>
                <a-menu-item key="/ledger/ledger" >
                  台账列表
                </a-menu-item>
              </a-sub-menu>

              <a-sub-menu key="employee" v-if="role && role.indexOf('06-')!==-1">
								<span slot="title">
									<a-icon type="team"/>员工管理
								</span>
                <a-menu-item key="/employee/employee" v-if="role && role.indexOf('06-1')!==-1">
                  员工列表
                </a-menu-item>
                <!--                <a-menu-item key="/competence/competence"  >-->
                <!--                  权限管理-->
                <!--                </a-menu-item>-->
              </a-sub-menu>

              <!--              <a-sub-menu key="menuList" >-->
              <!--								<span slot="title">-->
              <!--									<a-icon type="menu" />菜单管理-->
              <!--								</span>-->
              <!--                <a-menu-item key="/menu/menuList" >-->
              <!--                  菜单列表-->
              <!--                </a-menu-item>-->
              <!--              </a-sub-menu>-->

              <!--              <a-sub-menu key="roleList" >-->
              <!--								<span slot="title">-->
              <!--									<a-icon type="security-scan" />权限管理-->
              <!--								</span>-->
              <!--                <a-menu-item key="/role/roleList" >-->
              <!--                  权限列表-->
              <!--                </a-menu-item>-->
              <!--              </a-sub-menu>-->
              <a-sub-menu key="download" v-if="role && role.indexOf('07-')!==-1">
								<span slot="title">
									<a-icon type="download"/>软件下载
								</span>
                <a-menu-item key="/download/download" v-if="role && role.indexOf('07-1')!==-1">
                  软件下载
                </a-menu-item>
              </a-sub-menu>

              <!--              <a-sub-menu key="mark" v-if="role && role.indexOf('05-')!==-1">-->
              <!--								<span slot="title">-->
              <!--									<a-icon type="edit" />评分管理-->
              <!--								</span>-->
              <!--                <a-menu-item key="/mark/mark" v-if="role && role.indexOf('05-1')!==-1">-->
              <!--                  评分管理-->
              <!--                </a-menu-item>-->
              <!--                <a-menu-item key="/mark/rate" v-if="role && role.indexOf('05-2')!==-1">-->
              <!--                  打分-->
              <!--                </a-menu-item>-->
              <!--              </a-sub-menu>-->
            </a-menu>
          </a-layout-sider>
          <a-layout-content :style="{ padding: '0 24px', minHeight: '500px' }">
            <router-view></router-view>
          </a-layout-content>
        </a-layout>
      </a-layout-content>
      <a-layout-footer style="text-align: center">
        <a-row type="flex" justify="center">
          <a-col>@杭州申辉环保科技有限公司</a-col>

          <a-col :offset="1"><a href="http://beian.miit.gov.cn">浙ICP备19000748号-3</a></a-col>
          <a-col :span="2" style="text-align: right"><span>友情链接:</span></a-col>
          <a-col :xs="2" :xl="2" :xxl="1" style="text-align: center"><span><a href="http://www.cnsunhui.com"
                                                                              target="_blank">公司官网</a></span>
          </a-col>
          <a-col :xs="5" :xl="5" :xxl="3" v-if="version=='1'">
            <span>
              <a href="https://v.yuntus.com/tcv/5359739525959cc54868853ab63bed96e0a27291bac7a3729b13df2b0b545197"
                 target="_blank">盐都区大数据监控平台</a>
            </span>
          </a-col>
          <a-col :xs="5" :xl="5" :xxl="3" v-if="version=='2'">
            <span>
              <a href="https://qly.andmu.cn/normal#/login?redirect=%2Fcamera%2Fall"
                 target="_blank">建湖监控平台</a>
            </span>
          </a-col>
        </a-row>
      </a-layout-footer>
    </a-layout>
    <router-view v-if="!$route.meta.keepAlive"></router-view>

    <a-modal v-model="logoutVisible" @ok="logoutOk" okText="确认" cancelText="取消" @cancel="logoutCancel">
      <p>确认要退出本系统吗</p>
    </a-modal>

    <a-modal v-model="visible" @ok="handleOk" okText="确定" cancelText="取消" @cancel="handleCancel">
      <p>请输入旧密码
        <a-input v-model='oldPassword'>
          <a-icon slot="prefix" type="unlock"/>
        </a-input>
      </p>
      <p>请输入新密码
        <a-input v-model="newPassword">
          <a-icon slot="prefix" type="history"/>
        </a-input>
      </p>
      <p>再次输入新密码
        <a-input v-model="newPassword2">
          <a-icon slot="prefix" type="check"/>
        </a-input>
      </p>
    </a-modal>
  </div>
</template>
<script>
import axios from "axios";

export default {
  data() {
    const role = sessionStorage.getItem("role");
    const name = sessionStorage.getItem("name");

    return {
      "role": role,
      "logoutVisible": false,
      version: '',
      visible: false,
      oldPassword: '',
      newPassword: '',
      newPassword2: '',
      employeeName: name
    };
  },
  watch: {
    $route() {
      const role = sessionStorage.getItem("role");
      const name = sessionStorage.getItem("name");
      this.version = sessionStorage.getItem("version");
      if (role != null) {
        this.role = role
      }
      this.employeeName = name;
    }
  },
  //页面初始化加载时执行的方法
  created() {
    if (sessionStorage.getItem("token") == null) {
      // 没有token
      this.$router.push("/login");
    }
  },
  methods: {
    //添加按钮事件
    add: function () {
      this.visible = true;
    },
    //弹出框确定按钮
    handleOk: function () {

      // this.visible = false;

      //第一步 判断新密码是否为空 两个任意一个为空都不合格
      //第二部 判断旧密码是否输入 如果旧密码===‘’ 那就不合格
      if (this.oldPassword === '' || this.newPassword === '' || this.newPassword2 === '') {
        this.$message.error("请确认密码是否输入");
      } else if (this.newPassword !== this.newPassword2) {
        this.$message.error("请确认新密码是否一致");
      } else if (this.newPassword.indexOf(" ") === -1) {
        axios.post("/admin/employee/changePassword", {
          'old': this.oldPassword,
          'new': this.newPassword,

        }).then((res) => {
          if (res.data.code === '200') {
            this.$message.success("密码修改成功,请重新登录");
            //清空绑定值
            this.clear();
            sessionStorage.removeItem("token");
            this.$router.push("/login");
          } else {
            this.$message.error(res.data.msg);
          }
        })
        //第三步  两次新密码是否一致  一致再发送请求

        //第四步 发送请求 判断服务器的响应值
        //如果修改密码成功  才可以把弹窗关闭 然后把内存中的值清空
        //如果修改密码不成功 则提醒用户哪里不成功 弹窗不关闭
      }
    },
    //弹出框点击取消的事件
    handleCancel: function () {
      this.editIndex();
      this.visible = false;
    },
    editIndex: function () {
      this.editName = "";
      this.editValue = "";
      this.editAmount = "";
      this.editId = "";
    },
    change: function (e) {
      if (e.key === '/user/logout') {
        this.$router.push("/login")
      } else {
        this.$router.push(e.key);
      }
    },
    logout: function () {
      this.logoutVisible = true
    },
    //编辑框确认
    logoutOk: function () {
      this.logoutVisible = false;
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("role");
      sessionStorage.removeItem("name");
      sessionStorage.removeItem("version");
      this.$router.push("/login");
      // window.open('about:blank', '_self').close()
    },


    //编辑框取消
    logoutCancel: function () {
      this.logoutVisible = false
    },

    clear: function () {
      this.visible = false;
      this.oldPassword = '';
      this.newPassword = '';
      this.newPassword2 = '';
    }
  },
};
</script>
