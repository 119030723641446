<template>
  <div>
    <a-breadcrumb style="padding-top: 15px">
      <a-breadcrumb-item><a href="/">首页</a></a-breadcrumb-item>
      <a-breadcrumb-item>垃圾投放</a-breadcrumb-item>
      <a-breadcrumb-item>建湖数据</a-breadcrumb-item>
    </a-breadcrumb>
    <a-page-header style="border: 1px solid rgb(235, 237, 240)" title="建湖数据"/>
    <br>

<!--    <a-row>-->
<!--      <a-col :xs="22" :xl="22" :xxl="22"></a-col>-->
<!--      <a-col :span="1">-->
<!--        <a-button type="primary" v-on:click="download">-->
<!--          文件导出-->
<!--        </a-button>-->
<!--      </a-col>-->
<!--    </a-row>-->
<!--    <br>-->

    <a-row type="flex">
      <a-col><span style="line-height: 32px">小区名称：</span>
        <a-select style="width: 120px" v-model="areaCode" @change="handleAreaChange">
          <a-select-option v-for="area in areaList" :key="area.code">
            {{ area.name }}
          </a-select-option>
        </a-select>
      </a-col>
    </a-row>
    <br>

    <a-row type="flex">
      <a-col>
        <span style="line-height: 32px">时间：</span>
      </a-col>
      <a-col>
        <a-range-picker @change="onChange" :locale="locale" :value="createValue"/>
      </a-col>
    </a-row>
    <br>

    <a-row>
      <a-col offset="1">
        <a-button v-on:click="search" type="primary">查询</a-button>
      </a-col>
    </a-row>
    <br>

    <a-table :columns="columns" :data-source="list" :pagination="pagination" bordered="true"
             :rowKey="(record,index)=>{return index}" @change="handleTableChange">

      <span slot="createDate" slot-scope="createDate">
					{{ createDate.split(" ")[0] }}
			</span>

<!--      <span slot="garbageType" slot-scope="garbageType">-->
<!--				<a-tag-->
<!--            :color="garbageType === '1' ? '#2db7f5' : garbageType === '2' ? '#108ee9' : garbageType === '3' ? '#87d068' : garbageType === '4' ? '#FF6C37' : '#000066'">-->
<!--					{{ garbageType === '1' ? '纸张' : garbageType === '2' ? '塑料' : garbageType === '3' ? '金属' : garbageType === '4' ? '玻璃' : '织物' }}-->
<!--				</a-tag>-->
<!--			</span>-->
<!--      <span slot="operation" slot-scope="text,record">-->
<!--				<a-button v-on:click="edit(record.id)" type="primary">编辑</a-button>-->
<!--			</span>-->
    </a-table>

    <!--编辑弹窗-->
<!--    <a-modal v-model="editVisible" @ok="editHandleOk" okText="确定" cancelText="取消" @cancel="editHandleCancel">-->
<!--      <p>重量(kg)：-->
<!--        <a-input v-model="weightEdit" style="width: 120px"></a-input>-->
<!--      </p>-->
<!--      <p>时间：-->
<!--        <a-date-picker placeholder="选择时间" :locale="locale" show-time @change="timeChange"-->
<!--                       v-model="timeEdit"></a-date-picker>-->
<!--      </p>-->
<!--    </a-modal>-->

    <!--文件上传-->
<!--    <a-modal v-model="downVisible" @ok="downHandleOk" okText="确定" cancelText="取消" @cancel="downHandleCancel">-->
<!--      <p>小区名称：-->
<!--        <a-select style="width: 120px" v-model="areaCodeWithoutAll" @change="downHandleAreaChange">-->
<!--          <a-select-option v-for="area in areaWithoutAllList" :key="area.code">-->
<!--            {{ area.name }}-->
<!--          </a-select-option>-->
<!--        </a-select>-->
<!--      </p>-->
<!--      <p>时间：-->
<!--        <a-month-picker placeholder="选择月份" @change="monthChange"/>-->
<!--      </p>-->
<!--    </a-modal>-->
  </div>
</template>

<script>
import axios from "axios";
import locale from "ant-design-vue/lib/date-picker/locale/zh_CN";

const columns = [
  {
    title: '垃圾房名称',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: '厨余(kg)',
    dataIndex: 'kitchen',
    key: 'kitchen',
  },
  {
    title: '其他(桶)',
    dataIndex: 'other',
    key: 'other',
  },
  {
    title: '有害(kg)',
    dataIndex: 'harmful',
    key: 'harmful',
  },
  {
    title: '塑料(kg)',
    dataIndex: 'plastics',
    key: 'plastics',
  },
  {
    title: '纸张(kg)',
    dataIndex: 'paper',
    key: 'paper',
  },
  {
    title: '金属(kg)',
    dataIndex: 'metal',
    key: 'metal',
  },
  {
    title: '玻璃(kg)',
    dataIndex: 'glass',
    key: 'glass',
  },
  {
    title: '织物(kg)',
    dataIndex: 'clothes',
    key: 'clothes',
  },

  {
    title: '时间',
    dataIndex: 'createDate',
    key: 'createDate',
    scopedSlots: {
      customRender: 'createDate'
    }
  },
];
export default {
  data() {
    return {
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
        showTotal: total => `共 ${total} 条`, // 显示总数
      },
      columns,
      areaList: [],   //小区下拉框数据源
      list: [],
      areaCode: "", //当前选中的地区值
      startTime: "",
      endTime: "",
      createValue: [],
      editId: '',
      editVisible: false,
      weightEdit: '',
      timeEdit: '',
      downVisible: false,
      areaWithoutAllList: [],
      areaCodeWithoutAll: '',
      monthSelect: '',
      garbageType: '0',
      locale,
    }
  },
  created() {
    axios.post("/area/areaSelect", {}).then((res) => {
      if (res.data.code === '200') {
        //请求成功
        this.areaList = res.data.data;
        this.areaCode = this.areaList[0].code;
      }
    })

    this.getDate();
  },
  methods: {
    //下拉框选中事件
    handleAreaChange: function (value) {
      this.areaCode = value;
    },

    //without all
    // downHandleAreaChange: function (value) {
    //   this.areaCodeWithoutAll = value;
    // },

    // reset: function () {
      //清空搜索条件
      // this.status = "0"
      // this.areaCode = "all";
      // this.userName = "";
      // this.startTime = "";
      // this.endTime = "";
      // this.pagination.current = 1;
      // this.createValue = '';
      // this.getData();
    // },
    //搜索按钮
    search: function () {
      this.pagination.current = 1;
      this.getData();
    },

    //table 分页监听事件
    handleTableChange: function (pagination) {
      this.pagination = pagination;
    },

    //从服务器获取数据
    getData: function () {
      //从服务器获取数据
      axios.post("/jianhu2/list ", {
        "pageNumber": this.pagination.current,
        "code": this.areaCode,
        "startTime": this.startTime,
        "endTime": this.endTime,
      }).then((res) => {
        if (res.data.code === '200') {
          //执行成功
          this.list = res.data.data.list;
          this.pagination.total = parseInt(res.data.data.count);
        }
      })
    },

    //获取当前日期
    getDate: function () {
      let date = new Date();
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      let sevenDay = date.getTime()-7*86400*1000;
      date.setTime(sevenDay);
      this.startTime = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
      this.endTime = year + '-' + month + '-' + day;
      this.createValue = [this.startTime, this.endTime];

    },

    //时间选择框监听
    onChange(date, dateString) {
      this.createValue = dateString;
      this.startTime = dateString[0];
      this.endTime = dateString[1];
    },

    //弹窗时间选择
    // timeChange(value, dateString) {
    //   console.log(value);
    //   this.timeEdit = dateString;
    //
    // },

    //编辑按钮
    // edit: function (id) {
    //   this.editVisible = true;
    //   this.editId = id;
    //   axios.post("/jianhu/recycleEdit", {
    //     "id": this.editId
    //   }).then((res) => {
    //     if (res.data.code === '200') {
    //       //执行成功
    //       this.weightEdit = res.data.data.weight;
    //       this.timeEdit = res.data.data.createDate;
    //     }
    //   })
    // },

    //编辑弹窗确定按钮
    // editHandleOk: function () {
    //   axios.post("/jianhu/recycleUpdate", {
    //     "id": this.editId,
    //     "weight": this.weightEdit,
    //     "createDate": this.timeEdit
    //   }).then((res) => {
    //     if (res.data.code === '200') {
    //       //执行成功
    //       this.editVisible = false;
    //       this.$message.success(res.data.msg);
    //       this.getData();
    //     } else {
    //       this.$message.error(res.data.msg);
    //     }
    //   })
    //
    // },

    //编辑弹窗取消按钮
    // editHandleCancel: function () {
    //   this.editVisible = false;
    // },

    //文件导出
    // download: function () {
    //   axios.post("/area/areaSelectWithoutAll", {}).then((res) => {
    //     if (res.data.code === '200') {
    //       //请求成功
    //       this.areaWithoutAllList = res.data.data;
    //       this.areaCodeWithoutAll = this.areaWithoutAllList[0].code;
    //     }
    //   });
    //   this.downVisible = true;
    // },

    //文件上传确定
    // downHandleOk: function () {
    //   window.open("http://gxhbpaas.cn:9997/jianhu/recycleExcel?areaCode=" + this.areaCodeWithoutAll + "&createDate=" +
    //       this.monthSelect, "_blank");
    //   this.downVisible = false;
    //
    // },

    //文件上传取消
    // downHandleCancel: function () {
    //   this.downVisible = false;
    // },

    //月份选择
    // monthChange(date, dateString) {
    //   this.monthSelect = dateString;
    // },

    //垃圾类型选择
    // typeHandleChange(value) {
    //   this.garbageType = value;
    // }
  },
  watch: {
    pagination() {
      this.getData();
    },
    areaCode() {
      this.pagination.current = 1;
      this.getData();
    }
  }
}
</script>
