<template>
  <div>
    <a-breadcrumb style="padding-top: 15px">
      <a-breadcrumb-item><a href="/">首页</a></a-breadcrumb-item>
      <a-breadcrumb-item>台账管理</a-breadcrumb-item>
      <a-breadcrumb-item>台账列表</a-breadcrumb-item>
    </a-breadcrumb>
    <a-page-header style="border: 1px solid rgb(235, 237, 240)" title="台账列表"/>
    <br>
    <a-row type="flex">
      <a-col><span style="line-height: 32px">小区名称：</span>
        <a-select style="width: 120px" v-model="areaCode" @change="handleAreaChange" show-search
                  :filter-option="filterOption">
          <a-select-option v-for="area in areaList" :key="area.code">
            {{ area.name }}
          </a-select-option>
        </a-select>
      </a-col>
    </a-row>

    <br>
    <a-table :columns="columns" :data-source="list" :pagination="pagination"
             :rowKey="(record,index)=>{return index}" @change="handleTableChange">
<span slot="img" slot-scope="img">
  <a-tag :key="img">
					<img v-bind:src="img" class="appealImg" preview="0"  />
				</a-tag>
			</span>
      <span slot="type" slot-scope="type">
<!--				<a-tag :color="type === '1' ? '#2db7f5' : garbageType==='2'?'#108ee9':'#87d068'">-->
				<a-tag color='#2db7f5'>
					{{
            type === '1' ? '党员会议' : type === '2' ? '协商会议' : type === '3' ? '员工会议' : type === '4' ? '党员活动' : type === '5' ? '志愿者活动' : type === '6' ? '宣传活动' : type === '7' ? '入户宣传' : type === '8' ? '示范家庭' : type === '9' ? '分类达人' : type === '10' ? '抖音宣传' : type === '11' ? '微信宣传' : '宣传栏'
          }}
				</a-tag>
			</span>
      <span slot="operation" slot-scope="text,record">
				<a-button v-on:click="deleted(record.id)" type="danger">删除</a-button>
			</span>
    </a-table>
  </div>
</template>

<script>
import axios from "axios";

const columns = [{
  title: '小区名称',
  dataIndex: 'areaName',
  key: 'areaName',
},
  {
    title: '活动类型',
    dataIndex: 'type',
    key: 'type',
    scopedSlots: {
      customRender: 'type'
    }
  },
  {
    title: '上传时间',
    dataIndex: 'createTime',
    key: 'createTime',
  },
  {
    title: '投递照片',
    dataIndex: 'img',
    key: 'img',
    scopedSlots: {
      customRender: 'img'
    }
  },
  {
    title: '操作',
    dataIndex: 'operation',
    scopedSlots: {
      customRender: 'operation'
    }

  }
];
export default {
  data() {
    return {
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
        showTotal: total => `共 ${total} 条`, // 显示总数
      },
      columns,
      areaList: [],   //小区下拉框数据源
      list: [],
      areaCode: "", //当前选中的地区值
    }
  },
  created() {
    axios.post("/area/areaSelect", {}).then((res) => {
      if (res.data.code === '200') {
        //请求成功
        this.areaList = res.data.data;
        this.areaCode = this.areaList[0].code;
      }
    });
    this.getData();
  },
  methods: {
    filterOption(input, option) {
      return (
          option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    //下拉框选中事件
    handleAreaChange: function (value) {
      this.areaCode = value;
    },
    //table 分页监听事件
    handleTableChange: function (pagination) {
      this.pagination = pagination;
    },
    //从服务器获取数据
    getData: function () {
      //从服务器获取数据
      axios.post("/active/list", {
        "pageNumber": this.pagination.current,
        "areaCode": this.areaCode
      }).then((res) => {
        if (res.data.code === '200') {
          //执行成功
          this.list = res.data.data.list;
          this.pagination.total = parseInt(res.data.data.count);
        }
      })
    },
    deleted(value){
      axios.post("/active/delete", {
        "id": value,
      }).then((res) => {
        if (res.data.code === '200') {
          //请求成功
          this.$message.success(res.data.msg);
        }else{
          this.$message.error(res.data.msg);
        }
        this.getData();
      })

    },


  },
  watch: {
    pagination() {
      this.getData();
    },
    areaCode() {
      this.pagination.current = 1;
      this.getData();
    }
  }
}
</script>
<style>
.appealImg {
  height: 100px;
}
</style>

