<template>
	<div>
    <a-breadcrumb style="padding-top: 15px">
			<a-breadcrumb-item><a href="/">首页</a></a-breadcrumb-item>
			<a-breadcrumb-item>垃圾袋管理</a-breadcrumb-item>
			<a-breadcrumb-item>垃圾袋库存</a-breadcrumb-item>
		</a-breadcrumb>

		<a-page-header style="border: 1px solid rgb(235, 237, 240)" title="垃圾袋库存" /><br>

		<a-row>
      <a-col><span style="line-height: 32px">小区名称：</span>
        <a-select style="width: 120px" v-model="areaCode" @change="handleAreaChange">
          <a-select-option v-for="area in areaList" :key="area.code">
            {{ area.name }}
          </a-select-option>
        </a-select>

				<a-button type="primary" v-on:click="search" style="margin-left: 20px">
					搜索
				</a-button>
			</a-col>
		</a-row><br>


		<a-row>
			<a-col :xs="22" :xl="22" :xxl="23">
			</a-col>
		</a-row><br>

		<a-table :columns="columns" :data-source="list" :pagination="pagination" @change="handleTableChange"
			:rowKey="(record,index)=>{return index}">
			<span slot="operation" slot-scope="text,record">
				<a-button v-on:click="detail(record.areaCode)">变动详情</a-button>
				<a-button v-on:click="add(record.id)" type="primary">补货</a-button>
			</span>
		</a-table>

		<a-modal v-model="detailVisible" :width="800">
			<a-table :columns="detailColumns" :data-source="detailList" :rowKey="(record,index)=>{return index}">
				<span slot="type" slot-scope="type">
					<a-tag :color="type === '1' ? 'green' :  'red'">
						{{ type === '1' ? '补货' : '发袋' }}
					</a-tag>
				</span>
			</a-table>
			<template slot="footer">
				<a-button @click="detailHandleCancel">关闭</a-button>
			</template>
		</a-modal>

		<a-modal v-model="addlVisible" @ok="addHandleOk" okText="确定" cancelText="取消" @cancel="addHandleCancel">
			<p>补货数量：
				<a-input v-model="addCount" style="width: 150px;">

				</a-input>
			</p>
		</a-modal>


	</div>

</template>
<script>
	import axios from 'axios'

	const columns = [

		{
			title: '小区名称',
			dataIndex: 'areaName',
			key: 'areaName',
		},
		{
			title: '库存数量',
			dataIndex: 'count',
			key: 'count',

		},
		{
			title: '最近更改时间',
			dataIndex: 'lastChangeTime',
			key: 'lastChangeTime',
		},
		{
			title: '操作',
			dataIndex: 'operation',
			scopedSlots: {
				customRender: 'operation'
			}
		}
	];

	const detailColumns = [

		{
			title: '小区名称',
			dataIndex: 'areaName',
			key: 'areaName',
		},
		{
			title: '变动前库存',
			dataIndex: 'beforeStartStock',
			key: 'beforeStartStock',
		},
		{
			title: '变动数量',
			dataIndex: 'issueNumber',
			key: 'issueNumber',
		},
		{
			title: '现在库存',
			dataIndex: 'nowStartStock',
			key: 'nowStartStock',
		},
		{
			title: '变动方式',
			dataIndex: 'type',
			key: 'type',
			scopedSlots: {
				customRender: 'type'
			}
		},
		{
			title: '变动时间',
			dataIndex: 'createDate',
			key: 'createDate',
		},
	]
	export default {
		data() {
			return {
				pagination: {
					total: 0,
					current: 1,
					pageSize: 10,
					showTotal: total => `共 ${total} 条`, // 显示总数
				},
				list: [], //数据表格数据
				columns, //数据表格列名
        areaCode: "", //当前选中的地区值
        areaList:[],
				detailVisible: false,
				detailColumns, //详情表格数据
				detailList: [], //详情表格列名
				addlVisible: false,
				addCount: "", //补货数量
				addId: "" //补货id
			};
		},
		created() {
      axios.post("/area/areaSelect", {}).then((res) => {
        if (res.data.code === '200') {
          //请求成功
          this.areaList = res.data.data;
          this.areaCode = this.areaList[0].code;
        }
      })
			this.getData();
		},
		methods: {
			getData: function() {
				axios.post("/bag/list", {
					"areaCode": this.areaCode
				}).then((res) => {
					if (res.data.code === '200') {
						this.list = res.data.data;
					}
				})
			},
			handleChange: function(val) {
				this.areaCode = val;
			},
			//详情
			detail: function(record) {
				this.detailVisible = true;
				axios.post("/bag/detailList", {
					"areaCode": record
				}).then((res) => {
					if (res.data.code === '200') {
						this.detailList = res.data.data;
					}
				})
			},
			//明细弹窗取消
			detailHandleCancel: function() {
				this.detailVisible = false;
			},
			//补货按钮
			add: function(id) {
				this.addlVisible = true;
				this.addId = id;
			},
			//补货弹窗确定
			addHandleOk: function() {
				axios.post("/bag/add", {
					"id": this.addId,
					"amount": this.addCount
				}).then((res) => {
					if (res.data.code === '200') {
            this.$message.success(res.data.msg);
						this.addCount = "";
						this.getData();
						this.addlVisible = false;
					}
				})
			},
			//添加弹窗取消
			addHandleCancel: function() {
				this.addCount = "";
			},
			//table 分页监听事件
			handleTableChange: function(pagination) {
				this.pagination = pagination;
			},
			//搜索
			search: function() {
				this.pagination.current = 1;
				this.getData();
			},
      //获取子组件传值
      handleAreaChange: function(areaCode) {
        this.areaCode = areaCode;
      }
		},
		//监听事件
		watch: {

		},
	};
</script>
