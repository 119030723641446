<template>
  <div>
    <a-breadcrumb style="padding-top: 15px">
      <a-breadcrumb-item><a href="/">首页</a></a-breadcrumb-item>
      <a-breadcrumb-item>设备</a-breadcrumb-item>
      <a-breadcrumb-item>监控</a-breadcrumb-item>
    </a-breadcrumb>
    <a-page-header style="border: 1px solid rgb(235, 237, 240)" title="监控设备列表"/>
    <br>
    <a-row>
      <a-col>
        <span style="line-height: 32px">小区名称：</span>
        <a-select style="width: 120px" v-model="areaCode" @change="handleAreaChange">
          <a-select-option v-for="area in areaList" :key="area.code">
            {{ area.name }}
          </a-select-option>
        </a-select>

      </a-col>
    </a-row>
    <a-row>
      <a-col :span="23">
      </a-col>
      <a-col :span="1">
        <a-button type="primary" v-on:click="add">
          添加
        </a-button>
      </a-col>
    </a-row>
    <a-table :columns="columns" :data-source="list" :pagination="pagination"
             :rowKey="(record,index)=>{return index}" @change="handleTableChange">

			<span slot="status" slot-scope="text,record">
				<a-tag :color="record.status === '1' ? 'green' :  'red'">
					{{ record.status === '1' ? '正常' : '异常' }}
				</a-tag>
			</span>
      <span slot="operation" slot-scope="text,record">
				<a-button v-on:click="play(record)" type="dashed">网页播放</a-button>
<!--				<a-button v-on:click="mobilePlay(record)" type="dashed">手机播放</a-button>-->
        <a-button v-on:click="edit(record)">编辑</a-button>
			</span>
    </a-table>
    <a-modal v-model="addVisible" @ok="addHandleOk" okText="确定" cancelText="取消" @cancel="addHandleCancel">
      <p>设备名称：
        <a-input v-model="addTerminalName"></a-input>
      </p>
      <p>地址：
        <a-input v-model="addUrl"></a-input>
      </p>
    </a-modal>
    <a-modal v-model="editVisible" @ok="editHandleOk" okText="确定" cancelText="取消" @cancel="editHandleCancel">
      <p>设备名称：
        <a-input v-model="editTerminalName"></a-input>
      </p>
      <p>地址：
        <a-input v-model="editUrl"></a-input>
      </p>
    </a-modal>
  </div>
</template>
<script>
import axios from 'axios'

const columns = [

  {
    title: '设备名称',
    dataIndex: 'terminalName',
    key: 'terminalName',
  },

  {
    title: '状态',
    dataIndex: 'status',
    scopedSlots: {
      customRender: 'status'
    }
  },

  {
    title: '操作',
    dataIndex: 'operation',
    scopedSlots: {
      customRender: 'operation'
    }

  }
];
export default {
  data() {
    return {
      list: [], //数据表格数据
      columns, //数据表格列名
      pagination: {
        current: 1,
        pageSize: 10,
      },
      addVisible: false, //添加弹窗
      editVisible: false,
      addTerminalName: "", //添加弹出框设备名称
      addUrl: "", //添加弹出框设备地址
      editId: "", //编辑框数据主键Id
      editTerminalName: "", //编辑弹出框设备名称
      editUrl: "", //编辑弹出框设备地址
      areaCode: "", //当前选中的地区值
      areaList:[],
    };
  },
  created() {
    axios.post("/area/areaSelect", {}).then((res) => {
      if (res.data.code === '200') {
        //请求成功
        this.areaList = res.data.data;
        this.areaCode = this.areaList[0].code;

      }
    })

  },
  methods: {
    //点击添加按钮触发的事件
    add: function () {
      this.addVisible = true;
    },

    //分页监听方法
    handleTableChange: function (pagination) {
      this.pagination = pagination;
    },

    //添加弹出框点击确定触发的事件
    addHandleOk: function () {

      axios.post("/terminal/monitoringAdd", {
        "name": this.addTerminalName,
        "url": this.addUrl
      }).then((res) => {
        if (res.data.code === '200') {
          this.$message.success(res.data.msg);
          this.addVisible = false;
          this.addIndex();
          this.getData();
        }
      })
    },
    //添加弹出框点击取消的事件
    addHandleCancel: function () {
      this.addIndex();

    },

    //添加弹出框数据清空
    addIndex: function () {
      this.addTerminalName = "";
      this.addUrl = "";
    },


    //编辑按钮触发的事件
    edit: function (value){
      this.editVisible = true;
      this.editId = value.id;
      //请求数据
      axios.post("/terminal/monitoringEdit", {
        "id": this.editId,
      }).then((res) => {
        if (res.data.code === '200') {
          this.editUrl = res.data.data.url;
          this.editTerminalName = res.data.data.terminalName;
        }
      })
    },
    //编辑弹出框点击确定触发的事件
    editHandleOk: function () {
      this.visible = false;
      axios.post("/terminal/monitoringUpdate", {
        "id": this.editId,
        "name": this.editTerminalName,
        "url": this.editUrl
      }).then((res) => {
        if (res.data.code === '200') {
          this.$message.success(res.data.msg);
          this.editVisible = false;
          this.editIndex();
          this.getData();
        }
      })
    },
    //编辑弹出框点击取消的事件
    editHandleCancel: function () {
      this.visible = false;
      this.terminalName = "";
      this.url = ""

    },
    editIndex: function () {
      this.editId = "";
      this.editTerminalName = "";
      this.editUrl = ""
    },

    getData: function () {
      axios.post("/terminal/monitoringList", {
        "areaCode": this.areaCode
      }).then((res) => {
        if (res.data.code === '200') {
          this.list = res.data.data.list;
        }
      })
    },
    //查看按钮
    play: function (record) {
      let routeUrl = this.$router.resolve({
        path: "/video",
        query: {
          url: record.url
        }
      });
      window.open(routeUrl.href, '_blank');
    },
    //手机播放按钮
    mobilePlay: function (record) {
      window.open(record.url, '_blank');
    },
    //获取子组件传值
    handleAreaChange: function(areaCode) {
      this.areaCode = areaCode;
    }
  },

  //监听事件
  watch: {
    pagination() {
      this.getData();
    },

    areaCode(){
      this.pagination.current = 1;
      this.getData();
    }


  }
};
</script>
